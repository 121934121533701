<template>
  <div style="display: grid; gap: 2rem">
    <FormLabel class="payment-form-label">
      <p>{{ $t('settings.card-holder') }}</p>
      <FormInput
        id="card-holder"
        v-model="forms.card.holder"
        class="NameElement"
        :pattern="REGEX.NAME"
        maxlength="50"
        minlength="2"
        required
        @valid="forms.vset.card.holder"
      />
    </FormLabel>

    <FormLabel class="payment-form-label">
      <p>{{ $t('settings.card-number') }}</p>
      <div id="card-number" style="text-align: center"></div>
    </FormLabel>

    <div
      style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 1rem"
    >
      <FormLabel class="payment-form-label">
        <p>{{ $t('settings.expiry-date') }}</p>
        <div id="card-expiry" button></div>
      </FormLabel>
      <FormLabel class="payment-form-label">
        <p>CCV</p>
        <div id="card-ccv" button></div>
      </FormLabel>
    </div>
  </div>
</template>

<script>
import { StripeProvider } from '../../services/payment-providers/StripeProvider'
import FormLabel from './FormLabel.vue'
import FormInput from '../../components/utils/FormInput.vue'
import { REGEX } from '../../constants/REGEX'

const stripeElementStyles = {
  base: {
    fontSize: '17px',
    color: 'black',
    letterSpacing: '0.01em',
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    '::placeholder': {
      color: '#00000000',
    },
  },
  invalid: {
    color: 'black',
  },
}

const currentYearShort = new Date(Date.now()).getFullYear() % 100
const options = {
  number: { showIcon: true },
  expiry: { placeholder: `04 / ${currentYearShort + 2}` },
  ccv: { placeholder: '123' },
}

export default {
  name: 'AddCardForm',
  components: { FormLabel, FormInput },
  mounted() {
    this.setUpStripe()
  },
  data() {
    return {
      REGEX,
      forms: {
        card: {
          holder: '',
          cardNumber: '',
          cardExpiry: '',
          cardCcv: '',
        },
      },
    }
  },
  watch: {
    'forms.vget.card.form': {
      immediate: true,
      handler(value) {
        this.$emit('valid', value)
      },
    },
  },
  methods: {
    addCard() {
      this.$emit('add-card-start')
      if (!this.forms.vget.card.form) return
      this.$store
        .dispatch('UserModule/addStripe', {
          cardNumber: this.forms.card.cardNumber,
          holder: this.forms.card.holder,
        })
        .then(
          ({ paymentMethod }) => this.$emit('add-card-success', paymentMethod),
          () => this.$emit('add-card-error'),
        )
        .finally(() => this.$emit('add-card-end'))
    },
    async setUpStripe() {
      try {
        const stripe = await StripeProvider.getInstance()
        const elements = stripe.elements()
        const createElement = (type, options = {}) =>
          elements.create(type, {
            ...options,
            classes: { base: 'StripeElement' },
            style: stripeElementStyles,
          })

        this.forms.card.cardCcv = createElement('cardCvc', options.ccv)
        this.forms.card.cardCcv.mount('#card-ccv')
        this.forms.card.cardCcv.on('change', ({ complete }) =>
          this.forms.vset.card.cardCcv(complete),
        )

        this.forms.card.cardExpiry = createElement('cardExpiry', options.expiry)
        this.forms.card.cardExpiry.mount('#card-expiry')
        this.forms.card.cardExpiry.on('change', ({ complete }) =>
          this.forms.vset.card.cardExpiry(complete),
        )

        this.forms.card.cardNumber = createElement('cardNumber', options.number)
        this.forms.card.cardNumber.mount('#card-number')
        this.forms.card.cardNumber.on('change', ({ complete }) =>
          this.forms.vset.card.cardNumber(complete),
        )
      } catch {
        this.$store.dispatch(
          'ToastModule/errror',
          'Failed to initialize stripe.',
        )
      }
    },
  },
}
</script>

<style scoped lang="scss">
.payment-form-label {
  text-transform: uppercase;
  color: #a0a0a0;
}
.NameElement {
  font-size: 16px;
  color: black;
  letter-spacing: 0.03em;
}

.StripeElement {
  box-shadow: 0 1px black;
  padding: 0.5rem;
  transition: var(--transition-default);
}

.StripeElement--focus {
  box-shadow: 0 2px black;
}
.StripeElement--invalid {
  box-shadow: 0 1px var(--color-error);
}
.StripeElement--focus.StripeElement--invalid {
  box-shadow: 0 2px var(--color-error);
}
</style>
